import {
  usePrepareShiftsSchedule,
  useSlots,
} from "features/api/hallschema-api";
import { useState } from "react";
import { Button } from "ui-kit";

import { FormContainer } from "./FormComponents";
import { TableModal } from "./TableModal/TableModal";

export const ReservationFormStepTable = () => {
  const [hallOpened, setHallOpened] = useState(false);

  // FIXME: получение слотов пока отсавил снаружи, т.к. пока предполагаю что на
  // самой форме так же будут использоваться слоты (причём будут доступны слоты
  // сразу всех залов). Если это не так — перенесу обратно в TableModal
  const { data: slots } = useSlots();
  usePrepareShiftsSchedule();

  return (
    <FormContainer>
      {hallOpened && (
        <TableModal
          slots={slots}
          onClose={() => {
            setHallOpened(false);
          }}
          // HINT: та же самая строка, что в шаге 2
          reservationInfo="2 guests, 10:30 p.m."
        />
      )}
      <Button variant="secondary" onClick={() => setHallOpened(!hallOpened)}>
        Open Hall Scheme
      </Button>
    </FormContainer>
  );
};
