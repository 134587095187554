import {
  GuestFormContainer,
  GuestHistory,
  GuestHistoryError,
  GuestInfo,
  GuestInfoError,
  GuestNotes,
  GuestNotesError,
} from "components/GuestInfo";
import { AppLayout, RestaurantLayout } from "components/Layout";
import { HallContainer } from "components/hall-scheme/redux/HallContainer";
import {
  Roles,
  RolesErrorBoundary,
  rolesAction,
  rolesLoader,
} from "components/settings/sub-renders/Roles";
import { config } from "config";
import {
  Auth,
  loginAction,
  loader as loginLoader,
  logoutAction,
} from "containers/Auth";
import { BookingOrders } from "containers/BookingOrders";
import { Dashboard } from "containers/Dashboard";
import {
  BookingChat,
  BookingDetailsHeader,
  BookingHistory,
  BookingInfo,
  BookingSlotDetailsError,
  EmptyDetails,
  ManagementDetailsHeader,
  ManagementInfo,
  ManagementSlotDetailsError,
  bookingSlotLoader,
  managementSlotLoader,
} from "containers/Dashboard/SlotDetails";
import { Dialog, Dialogs } from "containers/Dialogs";
import { loader as dialogLoader } from "containers/Dialogs/Dialog/Dialog";
import { FeedList } from "containers/Dialogs/FeedList/FeedList";
import {
  EditRecordFromDialog,
  loader as editRecordLoader,
} from "containers/Dialogs/ReservationFromDialog/EditRecordFromDialog";
import { ReservationFromDialog } from "containers/Dialogs/ReservationFromDialog/ReservationFromDialog";
import {
  ClientCard,
  Clients,
  EditSource,
  EmptyClientDetails,
  EmptySourceDetails,
  SourceCard,
  SourceCreationCard,
  SourceInfo,
  Sources,
} from "containers/Guests";
import { GuestsLayout } from "containers/Guests/GuestsLayout";
import { HallScheme } from "containers/HallScheme";
import { RequestsGrid } from "containers/RequestsGrid";
import { ReservationFormLayout } from "containers/ReservationForm/ui/ReservationFormLayout";
import { ReservationFormStep1 } from "containers/ReservationForm/ui/ReservationFormStep1";
import { ReservationFormStepTable } from "containers/ReservationForm/ui/ReservationFormStepTable";
import { ScheduleLanding } from "containers/ScheduleLanding";
import { Search } from "containers/Search";
import { Settings } from "containers/Settings";
import { createBrowserRouter, redirect } from "react-router-dom";

import { AppProvider } from "./providers/AppProvider";
import { RootProvider, rootLoader } from "./root";

export const router = createBrowserRouter([
  {
    path: "/",
    loader: rootLoader, //переместить ли?
    shouldRevalidate: ({ nextUrl, defaultShouldRevalidate }) =>
      defaultShouldRevalidate || nextUrl.pathname === "/", //ревалидация, если идем в компоненте ниже, но с url /,
    element: <RootProvider />,
    children: [
      {
        element: <AppProvider />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                element: <RestaurantLayout />,
                children: [
                  {
                    path: "/dashboard",
                    element: <Dashboard />,
                    children: [
                      { index: true, element: <EmptyDetails /> },
                      {
                        loader: bookingSlotLoader,
                        path: "booking/:slotId",
                        element: <BookingDetailsHeader />,
                        errorElement: <BookingSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <BookingInfo />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "history",
                            element: <BookingHistory />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "chat",
                            element: <BookingChat />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                        ],
                      },
                      {
                        loader: managementSlotLoader,
                        path: "management/:slotId",
                        element: <ManagementDetailsHeader />,
                        errorElement: <ManagementSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <ManagementInfo />,
                            errorElement: <ManagementSlotDetailsError />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/hall-scheme",
                    element: <HallScheme />,
                  },
                  {
                    path: "/create-booking",
                    element: <HallContainer isCreateBookingOpened />,
                  },
                  {
                    path: "/hall",
                    element: <HallContainer />,
                  },
                  {
                    path: "/requests",
                    element: <BookingOrders />,
                  },
                  {
                    path: "/guests",
                    element: <GuestsLayout />,
                    children: [
                      { index: true, loader: () => redirect("clients") },
                      {
                        path: "clients",
                        element: <Clients />,
                        children: [
                          {
                            index: true,
                            element: <EmptyClientDetails />,
                          },
                          {
                            path: "create",
                            element: <GuestFormContainer />,
                          },
                          {
                            path: ":clientId",
                            element: <ClientCard />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect("info"),
                              },
                              {
                                path: "info",
                                element: <GuestInfo />,
                                errorElement: <GuestInfoError />,
                              },
                              {
                                path: "history",
                                element: <GuestHistory />,
                                errorElement: <GuestHistoryError />,
                              },
                              {
                                path: "notes",
                                element: <GuestNotes />,
                                errorElement: <GuestNotesError />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "sources",
                        element: <Sources />,
                        children: [
                          {
                            index: true,
                            element: <EmptySourceDetails />,
                          },
                          {
                            path: "create",
                            element: <SourceCreationCard />,
                          },
                          {
                            path: ":sourceId",
                            element: <SourceCard />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect("info"),
                              },
                              {
                                path: "info",
                                element: <SourceInfo />,
                                errorElement: <GuestInfoError />,
                              },
                              {
                                path: "edit",
                                element: <EditSource />,
                                errorElement: <GuestInfoError />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/grid",
                    loader: () =>
                      config.requestGrid ? null : redirect("/dashboard"),
                    element: <RequestsGrid />,
                  },
                  {
                    path: "/dialogs",
                    element: <Dialogs />,
                    children: [
                      {
                        path: ":dialogId",
                        loader: dialogLoader,
                        element: <Dialog />,
                        children: [
                          { index: true, element: <FeedList /> },
                          {
                            path: "create-booking",
                            element: <ReservationFromDialog />,
                          },
                          {
                            path: "edit/:record",
                            loader: editRecordLoader,
                            element: <EditRecordFromDialog />,
                          },
                          { path: "*", loader: () => redirect("/dialogs") },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/schedule-landing",
                    element: <ScheduleLanding />,
                  },
                  {
                    path: "/settings",
                    element: <Settings />,
                    children: [
                      {
                        path: "roles",
                        loader: rolesLoader,
                        element: <Roles />,
                        errorElement: <RolesErrorBoundary />,
                        action: rolesAction,
                      },
                    ],
                  },
                  {
                    path: "/search",
                    element: <Search />,
                  },
                ],
              },
              {
                path: "/create-booking-new",
                element: <ReservationFormLayout />,
                children: [
                  { loader: () => redirect("step-1"), index: true },
                  { path: "step-1", element: <ReservationFormStep1 /> },
                  { path: "step-2", element: <form>форма-2</form> },
                  { path: "step-3", element: <form>форма-3</form> },
                  { path: "step-4", element: <ReservationFormStepTable /> },
                  { path: "step-5", element: <form>форма-5</form> },
                  { path: "*", loader: () => redirect("/create-booking-new") },
                ],
              },
            ],
          },
        ],
      },
      {
        loader: loginLoader,
        path: "/login",
        element: <Auth />,
        action: loginAction,
      },
      {
        loader: () => redirect("/login"),
        path: "/logout",
        action: logoutAction,
      },
      { path: "*", loader: () => redirect("/dashboard") },
    ],
  },
]);
