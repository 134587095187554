import { HideWhen } from "components/HideWhen";
import { DashboardBookings } from "components/booking/DashboardBookings";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import React from "react";
import { Outlet } from "react-router-dom";
import { Spinner } from "ui-kit";

import useDashBoards from "./useDashBoards";

export function Dashboard() {
  const {
    hasBooking,
    isTablet,
    isCreatingBooking,
    mode,
    isGuestMode,
    close,
    isRootDashboard,
  } = useDashBoards();

  /* const EditSection = useMemo(() => {
    if (hasBooking && !isManagerialTableBooking && booking?.slot_id) {
      return <EditBooking bookingId={booking.slot_id} />;
    } else if (hasBooking && isManagerialTableBooking) {
      return (
        <EditManagerialBooking booking={booking as unknown as ManagerSlot} />
      );
    } else {
      return emptyMessage;
    }
  }, [hasBooking, isManagerialTableBooking, booking, timestamp]);
 */
  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen
          condition={
            (isTablet && (hasBooking || !isRootDashboard)) ||
            (isTablet && isCreatingBooking)
          }
          noUnmount
        >
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      <CloseBookingContext.Provider value={hasBooking ? close : null}>
        <HideWhen condition={mode.includes("HALL") || isGuestMode} noUnmount>
          <Outlet />
        </HideWhen>
      </CloseBookingContext.Provider>
    </>
  );
}

/*                 <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                  <Card.HeaderTabs.Item
                    tabName={Tab.Edit}
                    title={intl.formatMessage({
                      id: ETranslations.BOOKING_MANAGEMENT,
                    })}
                  />
                  <Card.HeaderTabs.Item
                    disabled={!hasBooking}
                    tabName={Tab.History}
                    title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
                  />
                  {booking && (
                    <Card.HeaderTabs.Item
                      disabled={!booking.client?.client_id}
                      tabName={Tab.Chat}
                      title={intl.formatMessage({ id: ETranslations.CHAT })}
                    />
                  )}
                </Card.HeaderTabs>
                <HideWhen condition={tab !== Tab.Edit} noUnmount>
                  <Card.Content noPadding>{EditSection}</Card.Content>
                </HideWhen>
                <HideWhen condition={tab !== Tab.History}>
                  {booking ? (
                    <BookingHistory booking={booking} isExpanded />
                  ) : (
                    emptyMessage
                  )}
                </HideWhen>
                {booking && booking.client?.client_id && (
                  <HideWhen condition={tab !== Tab.Chat}>
                    <Card.Content noPadding>
                      <BookingChat booking={booking} />
                    </Card.Content>
                  </HideWhen>
                )} */
