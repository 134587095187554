import dayjs from "dayjs";
import { useIntl } from "react-intl";

import { useReservationFormContext } from "../model/ReservationFormContext";
import {
  FormClose,
  FormContainer,
  FormFooter,
  FormHeader,
} from "./FormComponents";
import styles from "./ReservationFormStep1.module.scss";
import { SmallCalendar } from "./SmallCalendar";

export const ReservationFormStep1 = () => {
  const { formatMessage } = useIntl();
  const enabledDaysSet = new Set(["2024-10-15", "2024-10-16", "2024-10-22"]);
  const { formValues, updateValues } = useReservationFormContext();
  console.log(formValues);
  return (
    <FormContainer>
      <form
        className={styles.form}
        onChange={(e) => {
          const bookingDate = new FormData(e.currentTarget).get("bookingDate");
          bookingDate && updateValues({ bookingDate: String(bookingDate) });
        }}
      >
        <SmallCalendar
          name="bookingDate"
          defaultIsoDate={
            formValues.bookingDate || dayjs.tz().format("YYYY-MM-DD")
          }
        />
      </form>
    </FormContainer>
  );
};
