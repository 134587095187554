import cn from "classnames";
import { ClientInfoPopup } from "components/ClientInfoPopup";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Button, Input } from "ui-kit";

import { ICONS } from "../../../common/helpers";
import styles from "./Phone.module.scss";
import useChoicePhoneRedux from "./hooks/useChoicePhoneRedux";
import commonStyles from "./style.module.scss";

const ChoicePhoneRedux = ({
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  autoFocus,
  className,
  isNewDesign,
  touched,
  isCreateBooking,
  openGuestList,
  isFromDialog,
  ...props
}: any) => {
  const {
    clearPhoneNumber,
    showClients,
    client,
    isEmpty,
    showPopUp,
    isDisabled,
  } = useChoicePhoneRedux({
    value,
    onChange,
    disabled,
    isCreateBooking,
    openGuestList,
    isFromDialog,
  });
  // FIXME: см. FIXME в ReservationFromDIalog
  const showGuestIcon = !openGuestList && !isDisabled;
  return (
    <div className={cn(styles.container, className)}>
      <Input.Phone
        autoComplete="off"
        autoFocus={autoFocus}
        className={styles.phone}
        disabled={isDisabled}
        inputClassName={commonStyles.boldText}
        suffix={
          <>
            {isDisabled &&
              (isFromDialog ? (
                <button
                  type="button"
                  className={cn(styles.edit, isNewDesign && styles.editNew)}
                  onClick={clearPhoneNumber}
                >
                  <ICONS.Edit />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={clearPhoneNumber}
                  className={styles.clear}
                >
                  <ICONS.Cross height={20} width={20} />
                </button>
              ))}
            {showGuestIcon && (
              <ICONS.Guests
                className={styles.guestIconNew}
                height="100%"
                width="100%"
                onClick={showClients}
              />
            )}
          </>
        }
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        {...props}
      />
      {!disabled && !client && !isNewDesign && (
        <button
          className={styles.guestButton}
          type="button"
          onClick={showClients}
        >
          <ICONS.Guests
            className={styles.guestIcon}
            height="100%"
            width="100%"
          />
        </button>
      )}
      {showPopUp && (
        <ClientInfoPopup clientId={client!.client_id} placement="right-start">
          <Button
            className={commonStyles.clientIcon}
            type="button"
            variant="phantom"
          >
            <ICONS.Question />
          </Button>
        </ClientInfoPopup>
      )}
    </div>
  );
};

export const ChoiceClientPhoneInputRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoicePhoneRedux {...input} {...meta} {...rest} />;
