import cn from "classnames";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";

import { Button, ICONS } from "..";
import styles from "./ModeSwitch.module.scss";

export function ModeSwitch() {
  const [mode, setMode] = useLocalStorage<"light" | "dark" | undefined>(
    "ui:mode",
  );

  useEffect(() => {
    if (!mode) return;
    document.documentElement.dataset.mode = mode;
  }, [mode]);

  return (
    <Button
      variant="phantom"
      className={cn(styles.button, mode === "dark" && styles.dark)}
      onClick={() => {
        setMode(mode === "light" ? "dark" : "light");
      }}
    >
      <ICONS.ThemeSwitcher />
    </Button>
  );
}
