import cn from "classnames";
import {
  BookingsList,
  EmptyBookingList,
  GlobalSearchBookingList,
} from "components/BookingsList";
import { HideWhen } from "components/HideWhen";
import { Pagination } from "components/Pagination";
import { HallSchema } from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import { EditBooking } from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import { Modal } from "components/modal";
import dayjs from "dayjs";
import {
  fromProxySelectors,
  useFromProxyActions,
} from "features/BookingFormProxy";
import { SelectGuestFromList } from "features/ClientList/components/select-guest-from-list";
import {
  globalSearchSelectors,
  useGlobalSearchActions,
} from "features/GlobalSearch";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { hallModeSelector } from "features/HallSchema/selectors";
import { useLazyGlobalSearchQuery } from "features/api/bookings-api";
import { noop } from "lodash";
import type { BookingSlot } from "models/booking.model";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Client } from "types/client";
import { SearchContext } from "types/globalSearch";
import { Card } from "ui-kit";

import { useApplicationContextActions } from "../../features/AppContex";
import { useTimelineActions } from "../../features/Timeline";
import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { getBookingStartTime } from "../../utils";
import styles from "./Search.module.scss";

const EMPTY_LIST: BookingSlot[] = [];

export function Search() {
  const { getIntlEntityEdition, isRussianLocale, intl } = useIntlUtils();
  const params = useSelector(globalSearchSelectors.params);
  const skip = useMemo(() => {
    if (params.context === SearchContext.CLIENT_TAG)
      return params.keyword.length < 1;
    return params.keyword.length < 3;
  }, [params.context, params.keyword]);
  const [fetchSearch, { data, isFetching }] = useLazyGlobalSearchQuery();
  const { setPage } = useGlobalSearchActions();
  const { setClient, setOnlyBooking: setBooking } = useFromProxyActions();
  const booking = useSelector(fromProxySelectors.selectBooking);
  const { setTime, resetTimeShift } = useTimelineActions();
  const { setDate, setPlaceFromBooking } = useApplicationContextActions();

  const mode = useSelector(hallModeSelector);
  const { switchMode } = useHallSchemaActions();

  useEffect(() => {
    if (skip) return noop;
    const result = fetchSearch(params);

    return result.abort;
  }, [skip, params]);

  const closeHall = useCallback(
    () => switchMode(HallMode.TABLES),
    [switchMode],
  );
  const closeGuests = useCallback(
    () => switchMode(HallMode.TABLE_BOOKINGS_EDIT),
    [switchMode],
  );

  const clearBooking = useCallback(() => {
    setBooking(undefined);
    resetTimeShift();
    setDate(dayjs.tz().toISOString());
    closeHall();
  }, []);

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests],
  );

  const onSelectBook = useCallback((book: BookingSlot) => {
    setDate(dayjs(book.date).toISOString());
    setTime(getBookingStartTime(book.date, book.time));
    setBooking(book);
    setPlaceFromBooking(book);
  }, []);

  return (
    <div className={styles.container}>
      {data?.content.length ? (
        <GlobalSearchBookingList
          bookings={data.content}
          loading={isFetching}
          onClick={onSelectBook}
        />
      ) : (
        <EmptyBookingList loading={isFetching} />
      )}
      <Pagination
        className={styles.paging}
        currentPage={params.page}
        pageSize={params.size}
        total={data?.totalElements ?? 0}
        onChange={setPage}
      />
      <Modal
        isOpen={Boolean(booking.slot_id)}
        title={getIntlEntityEdition(
          isRussianLocale
            ? ETranslations.PLURAL_BOOKINGS_NOM
            : ETranslations.PLURAL_BOOKING,
        )}
        onClose={clearBooking}
      >
        <Modal.Content className={styles.modal} noPadding>
          <HideWhen
            condition={mode.includes("HALL") || mode.includes("GUEST")}
            noUnmount
          >
            <div>
              {booking.slot_id && (
                <EditBooking
                  bookingId={(booking as BookingSlot).slot_id}
                  hideCard
                />
              )}
            </div>
          </HideWhen>
          <HideWhen condition={!mode.includes("HALL")}>
            <Card onClose={closeHall}>
              <Card.Header
                title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
              />
              <Card.Content className={styles.scheme}>
                <HallSchema />
              </Card.Content>
            </Card>
          </HideWhen>
          <HideWhen
            condition={
              ![
                HallMode.BOOKING_GUEST,
                HallMode.TABLE_BOOKINGS_EDIT_GUEST,
              ].includes(mode)
            }
          >
            <div className={cn(styles.guests)}>
              <SelectGuestFromList
                onClose={closeGuests}
                onSelectGuest={handleSetClient}
              />
            </div>
          </HideWhen>
        </Modal.Content>
      </Modal>
    </div>
  );
}
