import { HallMode, useHallSchemaActions } from "features/HallSchema";
import {
  useLazyFetchClientQuery,
  useUpdateClientMutation,
} from "features/api/client-api";
import { useIsTabletBooking } from "hooks/useIsTablet";
import { useCallback } from "react";
import { type Location, useLocation, useNavigate } from "react-router-dom";

import { useIntlUtils } from "../../../../../hooks/useIntlUtils";
import { useCreateWithOverbooking } from "./useCreateWithOverbooking";

const useCreateBooking = (reset: any, isFromManagement?: boolean) => {
  const { getIntlEntityCreation, isRussianLocale, getIntlAddOf, intl } =
    useIntlUtils();
  const {
    create,
    createWithOverbooking,
    isConfirmationRequired,
    clearData,
    isCreating,
    hasManagerialError,
    closeModal,
  } = useCreateWithOverbooking();
  const navigate = useNavigate();
  const location = useLocation() as Location<{ from: string } | null>;
  const { switchMode } = useHallSchemaActions();
  const isTablet = useIsTabletBooking();
  const [update] = useUpdateClientMutation();
  const [getClient] = useLazyFetchClientQuery();

  const close = useCallback(() => {
    reset();

    if (!isFromManagement) {
      const from = location.state?.from;
      from !== location.pathname && navigate(from || "/dashboard");
    }
  }, [history, reset]);

  const createBooking = useCallback(
    async (data) => {
      const { client = {} } = data;

      if (client?.editClient && client?.surname && client?.client_id) {
        const { client_id, name, surname = "", middle_name = "" } = client;
        await getClient(client_id).then(async (res) => {
          const newUser = {
            ...res.data,
            surname,
            name,
            middle_name,
            type_authorization: "AUTH",
          };
          //@ts-ignore
          await update(newUser);
        });
      }
      await create({
        ...data,
        seatType:
          data?.seatType || (isFromManagement ? "MANAGER_BOOK" : "BOOK"),
      });
      close();
    },
    [create, reset, history],
  );

  const createWithOver = useCallback(async () => {
    await createWithOverbooking();
    close();
  }, [createWithOverbooking, reset]);

  const handleChangeMode = () => {
    switchMode(
      isTablet ? HallMode.MANAGERIAL_TABLET : HallMode.MANAGERIAL_BOOKING,
    );
  };

  return {
    isRussianLocale,
    getIntlEntityCreation,
    getIntlAddOf,
    handleChangeMode,
    intl,
    createBooking,
    isConfirmationRequired,
    clearData,
    createWithOver,
    isCreating,
    close,
    hasManagerialError,
    closeModal,
  };
};

export default useCreateBooking;
