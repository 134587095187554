import cn from "classnames";
import { formateDate } from "components/GuestInfo/GuestNotes/utils";
import { restaurantSelector } from "features/AppContex";
import { useFetchClientReviewsQuery } from "features/api/client-api";
import type { ClientReviews } from "models/client.model";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { ClientId } from "types/client";
import { ETranslations } from "types/translates";
import { ExpandableContent, Spinner, StarRating } from "ui-kit";

import shortUserInfoStyles from "../ShortUserInfo/ShortUserInfo.module.scss";
import styles from "./GuestReviews.module.scss";

const GuestReview = ({
  id,
  dateTime,
  restaurantName,
  publishStatus,
  rating,
  reviewText,
}: {
  id: number;
  dateTime: string;
  restaurantName: string;
  publishStatus: string;
  rating: number;
  reviewText: string;
}) => {
  return (
    <article className={styles.reviewCard}>
      <header>
        <time className={styles.dateTime}>{dateTime}</time>&nbsp;·&nbsp;
        <strong className={styles.restaurantName}>{restaurantName}</strong>
        &nbsp;·&nbsp;<em className={styles.publishStatus}>{publishStatus}</em>
      </header>
      <StarRating value={rating} />
      <section className={styles.content}>
        <ExpandableContent as="blockquote" id={id}>
          {reviewText}
        </ExpandableContent>
      </section>
    </article>
  );
};

const ReviewsContent = ({
  reviews,
  restaurantName,
}: {
  reviews: ClientReviews;
  restaurantName: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ul className={styles.list}>
      {reviews.map((r) => (
        <li key={r.user_id}>
          <GuestReview
            id={r.user_id}
            dateTime={formateDate(r.time_key, formatMessage)}
            restaurantName={restaurantName}
            publishStatus={
              r.publish
                ? formatMessage({ id: ETranslations.PUBLISHED })
                : formatMessage(
                    { id: ETranslations.BASE_NOT },
                    {
                      entity: formatMessage({
                        id: ETranslations.PUBLISHED,
                      }).toLowerCase(),
                    },
                  )
            }
            rating={r.assessment}
            reviewText={r.review}
          />
        </li>
      ))}
    </ul>
  );
};

const NoReviews = ({ error }: { error?: string }) => {
  const { formatMessage } = useIntl();
  return (
    <p className={styles.noList}>
      {formatMessage(
        {
          id: error ? ETranslations.ERROR_REVIEWS : ETranslations.EMPTY_REVIEWS,
        },
        { error },
      )}
    </p>
  );
};

export const GuestReviews = ({ clientId }: { clientId: ClientId }) => {
  const restaurant = useSelector(restaurantSelector);
  const { data, isLoading, error } = useFetchClientReviewsQuery({
    clientId,
    restaurantId: restaurant.restaurant_id,
  });
  const { formatMessage } = useIntl();
  return (
    <section className={styles.reviews}>
      <h2 className={styles.title}>
        {formatMessage({ id: ETranslations.REVIEWS })}
      </h2>
      {data?.length ? (
        <ReviewsContent
          reviews={data}
          restaurantName={restaurant.restaurant_name}
        />
      ) : isLoading ? (
        <Spinner />
      ) : (
        <NoReviews error={error && JSON.stringify(error)} />
      )}
    </section>
  );
};

export const LatestReview = ({ clientId }: { clientId: ClientId }) => {
  const restaurant = useSelector(restaurantSelector);
  const { formatMessage } = useIntl();
  const { data } = useFetchClientReviewsQuery({
    clientId,
    restaurantId: restaurant.restaurant_id,
  });
  return data?.length ? (
    <div className={cn(shortUserInfoStyles.item, shortUserInfoStyles.fullRow)}>
      <span className={shortUserInfoStyles.title}>
        {formatMessage({ id: ETranslations.LATEST_REVIEW })}
      </span>
      <span className={shortUserInfoStyles.description}>
        <strong className={styles.restaurantName}>
          {restaurant.restaurant_name}
        </strong>
        <StarRating value={data.at(-1)!.assessment} />
      </span>
    </div>
  ) : null;
};
