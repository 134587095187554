import cn from "classnames";
import { ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { ICONS, IconWithCaption } from "ui-kit";
import { ControlledCollapse } from "ui-kit/Collapse";

import styles from "./BookingListCollapse.module.scss";
import type { BookingModeType } from "./types";

export const BookingListCollapse = ({
  title,
  bookings,
  guests,
  bookingMode,
  bookingList,
  children,
}: {
  title: string;
  bookings: number;
  guests: number;
  bookingMode: BookingModeType;
  bookingList: ReactNode;
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={cn(styles.bookingList, { [styles.waitList]: isOpen })}>
        {bookingList}
      </div>
      {bookingMode === "active" && (
        <ControlledCollapse
          title={title}
          isOpen={isOpen}
          onChange={setIsOpen}
          addon={
            <span className={styles.counters}>
              <IconWithCaption caption={bookings}>
                <ICONS.Book width={24} height={24} />
              </IconWithCaption>
              <IconWithCaption caption={guests}>
                <ICONS.Guests width={24} height={24} />
              </IconWithCaption>
            </span>
          }
        >
          {children}
        </ControlledCollapse>
      )}
    </>
  );
};
