import { IResponse } from "models/common";
import {
  type AppointUser,
  type GrantedUser,
  GrantedUsersDTO,
  type User,
  UserGroup,
  type UserId,
} from "models/user.model";
import { Notification } from "services/notification";

import { ETranslations } from "../../types/translates";
import { api } from "./api";

interface UpdateUserPassword {
  old_password: string;
  new_password: string;
  userId: number;
}

export const userApi = api
  .enhanceEndpoints({ addTagTypes: ["Users", "Groups"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllGroups: build.query<UserGroup[], void>({
        query: () => ({
          url: "users/groups",
        }),
        transformResponse: (response: IResponse<UserGroup[]>) => {
          const result = UserGroup.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Groups"],
      }),
      getAllUsers: build.query<GrantedUser[], void>({
        query: () => ({
          url: "users/granted",
        }),
        transformResponse: (response: IResponse<GrantedUser[]>) => {
          const result = GrantedUsersDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Users"],
      }),
      getUserById: build.query<GrantedUser, UserId>({
        query: (userId) => ({
          url: `users/${userId}`,
          method: "GET",
        }),
        transformResponse: (response: IResponse<GrantedUser>) => response.data,
      }),
      updatePassword: build.mutation<unknown, UpdateUserPassword>({
        query: ({ userId, ...body }) => ({
          url: `/user/change_password/${userId}`,
          method: "POST",
          body,
        }),
      }),
      createUser: build.mutation({
        query: (user: AppointUser) => ({
          url: `/user`,
          method: "POST",
          body: user,
        }),
        invalidatesTags: ["Users"],
        async onQueryStarted(_args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (e) {
            console.error(e);
          }
        },
      }),
      deleteUser: build.mutation<unknown, number>({
        query: (userID) => ({
          url: `/user/${userID}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Users"],
        async onQueryStarted(_args, { queryFulfilled }) {
          await queryFulfilled;
          Notification.success({
            title: ETranslations.USER_SUCCESSFULLY_DELETED,
          });
        },
      }),
    }),
  });

export const {
  useGetUserByIdQuery,
  useUpdatePasswordMutation,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useGetAllGroupsQuery,
} = userApi;
