import cn from "classnames";
import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react";
import { FieldError } from "ui-kit";

import { Labeled } from "../Labeled";
import styles from "./Input.module.scss";
import { InputAddon } from "./InputAddon";

type BaseInputProps = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends Omit<BaseInputProps, "prefix"> {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputClassName?: string;
  invalid?: boolean;
  datalist?: ReactNode;
  error?: string | string[];
}

export const Input = forwardRef(
  (
    {
      label,
      className,
      prefix,
      suffix,
      required,
      inputClassName,
      invalid,
      datalist,
      error,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Labeled className={className} label={label} required={required}>
      <div className={styles.inputContainer}>
        <InputAddon className={styles.prefix}>{prefix}</InputAddon>
        <input
          {...props}
          className={cn(styles.input, inputClassName, {
            [styles.withPrefix]: !!prefix,
            [styles.withSuffix]: !!suffix,
            [styles.invalid]: !!invalid || error,
          })}
          ref={ref}
        />
        {datalist}
        <InputAddon className={styles.suffix}>{suffix}</InputAddon>
      </div>
      {error && <FieldError error={error} fieldName={`${label}`} />}
    </Labeled>
  ),
);
