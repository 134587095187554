import type { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import type { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import cn from "classnames";
import { InfiniteList } from "components/InfiniteList";
import {
  useFetchOrganizationPageQuery,
  useLazyFetchOrganizationPageQuery,
} from "features/api/source-api";
import { OrganizationId } from "models/source.model";
import {
  type OptionHTMLAttributes,
  type RefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import type { PageableResponse } from "types/response";
import { ETranslations } from "types/translates";
import { Button, Input, Spinner } from "ui-kit";
import { ArrowDown } from "ui-kit/ICONS/icons";

import styles from "./OrganizationInput.module.scss";
import { SourceFormSchema } from "./model/schema";

const DataListItem = forwardRef<
  HTMLOptionElement,
  OptionHTMLAttributes<HTMLOptionElement> & { children?: string }
>((props, ref) => {
  return (
    <option
      className={cn(styles.item, props.className)}
      label={props.label || props.children}
      ref={ref}
      {...props}
    />
  );
});

export const OrganizationInput = ({
  label,
  control,
}: {
  label?: string;
  control: Control<SourceFormSchema>;
}) => {
  const { formatMessage } = useIntl();
  const organizationId = useController({
    name: "organizationId",
    control,
    rules: { required: true },
  });
  const organizationName = useController({
    name: "organizationName",
    control,
  });
  const defaultState = {
    searchName: organizationName.field.value || "",
    showOptions: false,
  };
  const [datalistState, setDatalistState] = useState(defaultState);
  const [fetch] = useLazyFetchOrganizationPageQuery();
  const { data, isLoading, fulfilledTimeStamp, originalArgs } =
    useFetchOrganizationPageQuery({
      searchName: datalistState.searchName,
    });

  const handleCloseDetails = () => {
    setDatalistState((prev) => ({
      ...prev,
      showOptions: false,
    }));
    if (organizationName.field.value !== datalistState.searchName) {
      organizationId.field.onChange(undefined);
      organizationName.field.onChange(datalistState.searchName || undefined);
    }
  };
  const error =
    organizationName.fieldState.error?.message ||
    organizationId.fieldState.error?.message
      ? ([
          organizationName.fieldState.error?.message,
          organizationId.fieldState.error?.message,
        ].filter(Boolean) as string[])
      : undefined;

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        datalistState.showOptions && handleCloseDetails();
      }}
    >
      <Input
        label={label}
        placeholder={formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
        autoComplete="off"
        required
        className={styles.datalist}
        value={datalistState.searchName}
        onClick={() =>
          setDatalistState((prev) => ({
            ...prev,
            showOptions: !prev.showOptions,
          }))
        }
        onChange={({ currentTarget: { value } }) => {
          fetch({ searchName: value });
          setDatalistState((prev) => ({
            ...prev,
            searchName: value,
            showOptions: true,
          }));
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && handleCloseDetails();
          e.key === "Escape" && setDatalistState(defaultState);
        }}
        suffix={
          <ArrowDown
            className={cn(
              styles.arrow,
              datalistState.showOptions && styles.open,
            )}
          />
        }
        datalist={
          isLoading ? (
            <Spinner />
          ) : (
            Boolean(data?.content.length) &&
            datalistState.showOptions && (
              <datalist
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const element = e.target;

                  if (element instanceof HTMLOptionElement) {
                    setDatalistState({
                      searchName: element.label,
                      showOptions: false,
                    });
                    organizationId.field.onChange(
                      Number(element.value) as OrganizationId,
                    );
                    organizationName.field.onChange(element.label);
                  }
                }}
              >
                <InfiniteList
                  key={fulfilledTimeStamp}
                  initialItems={data!.content}
                  fetchNextPage={(page) =>
                    fetch({ searchName: datalistState.searchName, page })
                  }
                >
                  {(organization, ref) => (
                    <DataListItem
                      value={organization.id}
                      label={organization.name}
                      selected={organization.id === organizationId.field.value}
                      ref={ref as RefObject<HTMLOptionElement> | undefined}
                    />
                  )}
                </InfiniteList>
              </datalist>
            )
          )
        }
        error={error}
      />
    </OutsideClickHandler>
  );
};
