import { Sentry } from "common/sentry";
import { config } from "config";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import ru from "dayjs/locale/ru";
import { localeSelector } from "features/AppContex/selectors";
import { useTranslates } from "features/api/dictionaries-api";
import { useServiceWorker } from "hooks/useServiceWorker";
import { useUserLocale } from "hooks/useUserLocale";
import _ from "lodash";
import moment from "moment";
import { type ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { Provider, useSelector } from "react-redux";
import { LoaderFunctionArgs, Outlet, redirect, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "services/auth.service";
import { Notification } from "services/notification";
import { store } from "storage";
import "styles/normalize.scss";
import { ELocales } from "types/commons";
import { ETranslations } from "types/translates";
import { Spinner } from "ui-kit";



import "../../index.scss";
import "../../legacy.scss";


export const rootLoader = ({ request }: LoaderFunctionArgs) => {
  const pathname = new URL(request.url).pathname;
  const isRoot = pathname === "/";
  const isLogin = pathname === "/login";
  const authenticated = AuthService.isAuthenticated();
  return authenticated
    ? isRoot || isLogin
      ? redirect("/dashboard")
      : null
    : isLogin
      ? null
      : redirect(`/login?redirectTo=${pathname}`);
};

const DAYJS_LOCALE_DISPATCH = {
  [ELocales.ru_RU]: ru,
  [ELocales.en_EN]: en,
};

const IntlLocaleProvider = ({ children }: { children: ReactNode }) => {
  const userLocale = useSelector(localeSelector);
  const locale = userLocale?.split("_")[0].toLowerCase();
  const { isSuccess: translatesFulfilled, translates } =
    useTranslates(userLocale);

  moment.locale(locale);
  dayjs.locale(DAYJS_LOCALE_DISPATCH[userLocale]);

  return translatesFulfilled && locale ? (
    <IntlProvider defaultLocale={locale} locale={locale} messages={translates}>
      {children}
    </IntlProvider>
  ) : (
    <Spinner />
  );
};

const Root = () => {
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(
    AuthService.isAuthenticated(),
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    !authenticated &&
      pathname !== "/login" &&
      navigate(`/login?redirectTo=${pathname}`);
  }, [authenticated]);

  useEffect(() => {
    global?.channels?.auth && (global.channels.auth.cb = setAuthenticated);
  }, []);

  const { reloadPage, showReload, waitingWorker } = useServiceWorker();
  const { userLocale } = useUserLocale();
  const { translates } = useTranslates(userLocale);

  useEffect(() => {
    if (showReload && waitingWorker && !_.isEmpty(translates)) {
      Notification.warning({
        options: {
          autoClose: false,
          closeOnClick: false,
          draggable: true,
        },
        title: ETranslations.APP_VERSION_UPDATED,
        message: (
          <div>
            <button className="primary" type="button" onClick={reloadPage}>
              {translates[ETranslations.BASE_REFRESH]}
            </button>
          </div>
        ),
      });
    }
  }, [waitingWorker, showReload, reloadPage, translates]);

  return (
    <>
      <Helmet>
        <title>{config.brand_title}</title>
      </Helmet>
      <Outlet />
      <ToastContainer
        autoClose={3e3}
        position="bottom-right"
        closeOnClick
        draggable
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export const RootProvider = () => (
  <Provider store={store}>
    <IntlLocaleProvider>
      <Sentry.ErrorBoundary>
        <Root />
      </Sentry.ErrorBoundary>
    </IntlLocaleProvider>
  </Provider>
);