import cn from "classnames";
import type { ReactNode } from "react";
import { NavLink, type To, useLocation } from "react-router-dom";
import { Button, ICONS, LinkButton } from "ui-kit";

import styles from "./FormComponents.module.scss";

export const FormStepper = ({
  stepsInfo,
}: {
  // valid имеет три состояния: true = валидный, false = инвалидный, undefined = не проверялся
  stepsInfo: {
    title: string;
    description?: string;
    valid?: boolean;
  }[];
}) => {
  return (
    <nav className={styles.stepList}>
      <ul>
        {stepsInfo.map((info, index) => (
          <li
            key={info.title}
            className={cn(
              styles.stepItem,
              info.valid && styles.valid,
              info.valid === false && styles.invalid,
            )}
          >
            <NavLink
              to={`step-${index + 1}`}
              className={({ isActive, isPending }) =>
                cn(styles.navLink, isActive && styles.active)
              }
            >
              <strong className={styles.stepTitle}>{info.title}</strong>
              {info.description && (
                <span className={styles.stepDescription}>
                  {info.description}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export const FormHeader = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <header className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </header>
  );
};

export const FormClose = ({
  to,
  onClose,
}: {
  to: To;
  onClose?: () => void;
}) => {
  return (
    <LinkButton
      className={styles.closeButton}
      variant="phantom"
      to={to}
      onClick={onClose}
    >
      <ICONS.Cross />
    </LinkButton>
  );
};

export const FormContainer = ({ children }: { children: ReactNode }) => {
  return <main className={styles.content}>{children}</main>;
};

export const FormFooter = ({ children }: { children: ReactNode }) => {
  return <footer className={styles.footer}>{children}</footer>;
};
