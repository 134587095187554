import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import { TagsById } from "components/Tags";
import { config } from "config";
import { restaurantSelector } from "features/AppContex";
import { useClientListActions } from "features/ClientList";
import { clientListFilter, selectedTags } from "features/ClientList/selectors";
import {
  useFetchClientsPageQuery,
  useLazyFetchClientsPageQuery,
} from "features/api/client-api";
import { useClientTagsOptions } from "features/api/tags";
import { useIntlUtils } from "hooks/useIntlUtils";
import type { Client } from "models/client.model";
import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import type { ClientsFilter } from "services/clients.service";
import type { ClientId } from "types/client";
import { ETranslations } from "types/translates";
import { Button, Card, ICONS, Input, SelectCheckbox, Spinner } from "ui-kit";

import { TAGS_TITLES } from "../../../constants";
import styles from "./ClientList.module.scss";

const ClientItem = memo(
  forwardRef<
    HTMLDivElement,
    {
      client_id: number;
      vip: boolean | undefined;
      phone: string;
      fullName: string;
      tagsIdsString: string | undefined;
    }
  >(({ client_id, vip, phone, fullName, tagsIdsString }, ref) => {
    return (
      <article className={styles.clientCard} ref={ref}>
        <NavLink
          to={String(client_id)}
          className={({ isActive, isPending }) =>
            cn(styles.clientInfo, {
              [styles.active]: isActive,
              [styles.pending]: isPending,
            })
          }
        >
          <h3 className={styles.fullName}>{fullName}</h3>
          <p className={styles.phone}>
            <ICONS.USER_PHONE />
            <span>{phone}</span>
          </p>
          <div className={styles.tags}>
            {vip && <ICONS.VipSign />}
            {tagsIdsString && (
              <TagsById tagsIDs={tagsIdsString.split(",") as `${number}`[]} />
            )}
          </div>
        </NavLink>
        <ClientInfoPopup clientId={client_id as ClientId} placement="auto">
          <Button
            className={styles.clientDetail}
            type="button"
            variant="phantom"
          >
            <ICONS.Question />
          </Button>
        </ClientInfoPopup>
      </article>
    );
  }),
);

const ClientInfiniteList = ({
  initialClients,
}: {
  initialClients: Client[];
}) => {
  const { formatMessage } = useIntl();
  const [clientListState, setClientListState] = useState({
    clients: initialClients,
    nextPage: 1,
    hasMoreClients: true,
  });
  const [fetchNextPage, { isFetching }] = useLazyFetchClientsPageQuery();
  const observerRef = useRef<HTMLDivElement>(null);
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
  const filter = useSelector(clientListFilter);
  const updatedFilter: ClientsFilter = {
    ...filter,
    offset: filter.count * clientListState.nextPage,
  };
  useEffect(() => {
    const observerTarget = observerRef.current;
    const loadMoreButton = loadMoreButtonRef.current;
    if (!observerTarget || !loadMoreButton) return;

    const observer = new IntersectionObserver((entries) => {
      // Когда список загружается слишком быстро или загружен до конца, то entries будет только 1 - button
      if (entries[0].isIntersecting || entries[1]?.isIntersecting) {
        !isFetching &&
          clientListState.hasMoreClients &&
          fetchNextPage(updatedFilter).then((data) => {
            if (data.data) {
              setClientListState((prev) => ({
                clients: prev.clients.concat(data.data!),
                nextPage: prev.nextPage + 1,
                hasMoreClients: Boolean(data.data?.length),
              }));
            }
          });
      }
    });

    observer.observe(observerTarget);
    observer.observe(loadMoreButton);
    return () => observer.disconnect();
  }, [clientListState, updatedFilter]);
  return (
    <Card.Content className={styles.infiniteList}>
      {clientListState.clients.map((c, index) => (
        <ClientItem
          key={c.client_id}
          client_id={c.client_id}
          vip={config.vipSign && c.vip}
          phone={c.phone ? getMaskPhone(c.phone) : "N/A"}
          fullName={getFullName(c.name, c.middle_name, c.surname) || "N/A"}
          tagsIdsString={c.tags?.length ? c.tags.join() : undefined}
          ref={
            index ===
            Math.floor(
              Math.max(
                clientListState.clients.length / 2,
                clientListState.clients.length - updatedFilter.count,
              ),
            )
              ? observerRef
              : undefined
          }
        />
      ))}
      {clientListState.clients.length >= filter.count && (
        <Button
          variant="secondary"
          className={styles.loadMoreButton}
          ref={loadMoreButtonRef}
          disabled={!clientListState.hasMoreClients}
          onClick={() => {
            !isFetching &&
              clientListState.hasMoreClients &&
              fetchNextPage(updatedFilter).then((data) => {
                if (data.data) {
                  setClientListState((prev) => ({
                    clients: prev.clients.concat(data.data!),
                    nextPage: prev.nextPage + 1,
                    hasMoreClients: Boolean(data.data?.length),
                  }));
                }
              });
          }}
        >
          {formatMessage({
            id: isFetching
              ? ETranslations.LOADING
              : clientListState.hasMoreClients
                ? ETranslations.LOAD_MORE
                : ETranslations.NOTHING_TO_LOAD,
          })}
        </Button>
      )}
    </Card.Content>
  );
};

export const ClientList = () => {
  const restaurantId = useSelector(restaurantSelector).restaurant_id;
  const tagsOptions = useClientTagsOptions(restaurantId);
  const userSelectedTags = useSelector(selectedTags);
  const { getIntlChooseEntity } = useIntlUtils();
  const filter = useSelector(clientListFilter);
  const { updateFilter } = useClientListActions();
  const { data, fulfilledTimeStamp } = useFetchClientsPageQuery(filter);

  const handleOnInputTerm = (value: string) => {
    updateFilter({ term: value || undefined });
  };
  const handleTagsChange = (tags: { value: number; label: string }[] = []) =>
    updateFilter({ tags: tags.map((it) => it.value) });

  const getFromFilter = () =>
    tagsOptions.filter((it) => userSelectedTags?.includes(it.value));

  return !data ? (
    <Spinner />
  ) : (
    <Card className={styles.list}>
      <section className={styles.subHeader}>
        <Input.Search onSearch={handleOnInputTerm} />
        <SelectCheckbox
          options={tagsOptions}
          placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
          titles={TAGS_TITLES}
          value={getFromFilter()}
          // @ts-ignore
          onChange={handleTagsChange}
        />
      </section>
      {/* ключ, чтобы обнулять стейт при изменении начальных клиентов, в целом можно и другой ключ */}
      <ClientInfiniteList initialClients={data} key={fulfilledTimeStamp} />
    </Card>
  );
};
