import dayjs from "dayjs";
import { useApplicationContextActions } from "features/AppContex";
import {
  fromProxySelectors,
  useFromProxyActions,
} from "features/BookingFormProxy";
import { FormBooking } from "features/BookingFormProxy/types";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import {
  useGetBookingQuery,
  useSetStatusMutation,
  useUpdateBookingFromFormHandler,
} from "features/api/bookings-api";
import {
  useCreateClientMutation,
  useLazyFetchClientDetailQuery,
  useLazyFetchClientQuery,
  useUpdateClientMutation,
} from "features/api/client-api";
import { invalidateHallSlots } from "features/api/hallschema-api";
import { useBooleanState } from "hooks/useBooleanState";
import type { BookingSlot, SlotId } from "models/booking.model";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Booking } from "types/booking";
import type { ErrorResponse } from "types/commons";
import { getBookingStartTime } from "utils";

const useEditBooking = (slot_id: SlotId) => {
  const [hasManagerialError, setHasManagerialError] = useState<boolean>(false);
  const { data: booking } = useGetBookingQuery(slot_id);
  const { updateBookingHandler } = useUpdateBookingFromFormHandler();
  const [setStatus] = useSetStatusMutation();
  const { setEditMode, setOnlyBooking } = useFromProxyActions();
  const [createGuest] = useCreateClientMutation();
  const [getUser] = useLazyFetchClientDetailQuery();
  const [overbookingData, setOverbookingData] = useState<
    FormBooking | undefined
  >();
  const selectedBooking = useSelector(
    fromProxySelectors.selectBooking,
  ) as Booking;
  const { setPlaceFromBooking, setDate } = useApplicationContextActions();
  const { switchMode } = useHallSchemaActions();

  const { setTime } = useTimelineActions();
  const onSelectBook = useCallback(
    (book: BookingSlot) => {
      setTime(getBookingStartTime(book.date, book.time));
      setPlaceFromBooking(book);
      setOnlyBooking(book);
      switchMode(HallMode.TABLES);
      setDate(dayjs(book.date).toISOString());
    },
    [selectedBooking],
  );
  useEffect(() => {
    booking && onSelectBook(booking);
    () => {
      setDate(dayjs.tz().toISOString());
    };
  }, [booking?.slot_id]);

  const dispatch = useDispatch();
  const [isUpdating, start, stop] = useBooleanState();
  const [updateGuest] = useUpdateClientMutation();
  const [getClient] = useLazyFetchClientQuery();
  const closeModal = () => setHasManagerialError(false);

  const update = useCallback(
    async (data) => {
      start();
      let { client } = data;
      try {
        const { client_id, phone, email } = data;

        if (client) {
          const {
            editClient = false,
            name,
            surname = "",
            middle_name = "",
          } = client;
          if (!client.client_id) {
            const {
              data: { client_id: newClientId },
            } = await createGuest({
              middle_name,
              name,
              phone,
              surname,
              email,
            }).unwrap();
            client = await getUser(newClientId).unwrap();
          }
          if (editClient && client_id) {
            getClient(client_id)
              .then(async (response) => {
                const newUser = {
                  ...response.data,
                  surname,
                  name,
                  middle_name,
                  type_authorization: "AUTH",
                };
                return newUser;
              })
              .then(async (newUser) => {
                //@ts-ignore
                await updateGuest(newUser);
              });
          }
        }
        await updateBookingHandler({
          ...data,
          contact: data.contact,
          client: Object.keys({
            ...client,
            client_id: client_id ?? client?.client_id,
          }).length
            ? client
            : undefined,
        }).unwrap();
        dispatch(invalidateHallSlots());
      } catch (e) {
        const errorData = (e as ErrorResponse["error"])?.data;
        errorData?.errorCode === 10100 && setOverbookingData(data);
        errorData?.errorCode === 10400 && setHasManagerialError(true);
        throw e;
      } finally {
        stop();
      }
    },
    [updateBookingHandler, setOverbookingData, dispatch],
  );

  const clearData = useCallback(
    () => setOverbookingData(undefined),
    [setOverbookingData],
  );

  const updateWithOverbooking = useCallback(async () => {
    if (!overbookingData) return;
    start();
    try {
      await updateBookingHandler({ ...overbookingData, force: true }).unwrap();
      dispatch(invalidateHallSlots());
      clearData();
    } finally {
      stop();
    }
  }, [overbookingData, setStatus, updateBookingHandler, dispatch]);

  useEffect(
    () => () => {
      setEditMode(false);
    },
    [setEditMode],
  );

  return {
    booking,
    update,
    overbookingData,
    clearData,
    updateWithOverbooking,
    hasManagerialError,
    closeModal,
    isUpdating,
  };
};

export default useEditBooking;
