import cn from "classnames";
import { HideWhen } from "components/HideWhen";
import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import { DialogsError } from "containers/Dialogs/DialogsError";
import { useFromProxyActions } from "features/BookingFormProxy";
import { SelectGuestFromList } from "features/ClientList/components/select-guest-from-list";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { useOutletContext, useSearchParams } from "react-router-dom";
import type { Client } from "types/client";
import { Card, Spinner } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { ETranslations } from "../../../types/translates";
import type { Tab } from "../constans";
import styles from "./ReservationFromDialog.module.scss";
import { useReservationFromDialog } from "./useReservationFromDialog";

const ReservationContent = ({
  openGuestList,
}: {
  openGuestList: () => void;
}) => {
  const {
    createBooking,
    isConfirmationRequired,
    clearData,
    createWithOver,
    isCreating,
    hasManagerialError,
    closeModal,
    isLoading,
    error,
    clientData,
    formatMessage,
    changeRestaurant,
  } = useReservationFromDialog();

  return (
    <>
      {error ? (
        <DialogsError
          message={formatMessage(
            { id: ETranslations.ERROR_FORM_RESERVATION },
            { error: JSON.stringify(error || "") },
          )}
        />
      ) : isLoading ? (
        <Spinner />
      ) : (
        <BookingFormReduxProxy
          submitFn={createBooking}
          openGuestList={openGuestList}
          clientData={clientData}
          onNavigateFromDialog={changeRestaurant}
        />
      )}
      <ConfirmOverbookingModal
        disabled={isCreating}
        isOpen={isConfirmationRequired}
        onConfirm={createWithOver}
        onDecline={clearData}
      />
      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </>
  );
};

const CardContent = ({
  dialogError,
  isLoading,
  openGuestList,
}: {
  dialogError: string | undefined;
  isLoading: boolean;
  openGuestList: () => void;
}) => (
  <Card.Content noPadding className={styles.fullHeight}>
    {dialogError ? (
      <DialogsError message={dialogError} />
    ) : isLoading ? (
      <Spinner />
    ) : (
      <ReservationContent openGuestList={openGuestList} />
    )}
  </Card.Content>
);

const ReservationFromDialogWrapper = ({
  headerText,
  children,
}: {
  headerText: string;
  children: ReactNode;
}) => {
  const isTablet = useIsTablet();

  return isTablet ? (
    <Card.HeaderTabs.Item<Tab> title={headerText} tabName="FEEDS">
      {children}
    </Card.HeaderTabs.Item>
  ) : (
    <Card>
      <Card.Header title={headerText} needToReset />
      {children}
    </Card>
  );
};

export const ReservationFromDialog = () => {
  const { formatMessage } = useIntl();
  const { isLoading, dialogError } = useOutletContext<{
    isLoading: boolean;
    dialogError: string | undefined;
  }>();
  const { setClient } = useFromProxyActions();
  const [searchParams, setSearchParams] = useSearchParams();
  const isGuestListOpen = searchParams.has("mode", "GUESTS");

  const openGuestList = () =>
    setSearchParams((prev) => (prev.set("mode", "GUESTS"), prev));

  const closeGuestList = () =>
    setSearchParams((prev) => (prev.delete("mode"), prev));

  const handleSetClient = (client: Client) => {
    setClient({ client });
    closeGuestList();
  };

  // FIXME: сейчас фича со списком гостей выключена. Включить или полностью её убрать, когда станет её будущее станет более ясным
  return (
    <>
      <section
        className={cn(styles.reservationForm, {
          //   [styles.hide]: (isGuestListOpen),
        })}
      >
        <ReservationFromDialogWrapper
          headerText={formatMessage({ id: ETranslations.CREATION_RESERVATION })}
        >
          <CardContent
            dialogError={dialogError}
            isLoading={isLoading}
            openGuestList={openGuestList}
          />
        </ReservationFromDialogWrapper>
      </section>
      {false && (
        <SelectGuestFromList
          onClose={closeGuestList}
          onSelectGuest={handleSetClient}
        />
      )}
    </>
  );
};
