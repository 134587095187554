import type { Dayjs } from "dayjs";
import { type ReactNode, createContext, useContext, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { invariant } from "utils";

type ReservationFormContext = {
  formValues: { bookingDate: string };
  updateValues: (newValues: { bookingDate: string }) => void;
};

export const useReservationFormContext = () => {
  const value = useOutletContext<ReservationFormContext | null>();
  return (value ||
    invariant(
      value !== null,
      "ReservationForm context not passed, please wrap your components with <ReservationFormProvider />",
    )) as ReservationFormContext;
};
