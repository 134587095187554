import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  accessRuleReducer,
  accessRulesSliceName,
} from "features/AccessRules/slice";
import { timelineReducer, timelineSliceName } from "features/Timeline/slice";

import {
  applicationContextReducer,
  applicationContextSliceName,
} from "./features/AppContex/slice";
import {
  formProxySliceName,
  formProxySliceReducer,
} from "./features/BookingFormProxy/slice";
import {
  clientListReducer,
  clientListSliceName,
} from "./features/ClientList/slice";
import {
  globalSearchReducer,
  globalSearchSliceName,
} from "./features/GlobalSearch/slice";
import {
  hallSchemaSliceName,
  hallSchemaSliceReducer,
} from "./features/HallSchema/slice";
import {
  moveBookingReducers,
  moveBookingSliceName,
} from "./features/MoveBooking/slice";
import {
  tableBookingListReducer,
  tableBookingListSliceName,
} from "./features/TableBooking/slice";
import { api, api2, apiFrontOffice, relativePathApi } from "./features/api/api";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [api2.reducerPath]: api2.reducer,
  [relativePathApi.reducerPath]: relativePathApi.reducer,
  [apiFrontOffice.reducerPath]: apiFrontOffice.reducer,
  [clientListSliceName]: clientListReducer,
  [timelineSliceName]: timelineReducer,
  [hallSchemaSliceName]: hallSchemaSliceReducer,
  [accessRulesSliceName]: accessRuleReducer,
  [formProxySliceName]: formProxySliceReducer,
  [tableBookingListSliceName]: tableBookingListReducer,
  [moveBookingSliceName]: moveBookingReducers,
  [applicationContextSliceName]: applicationContextReducer,
  [globalSearchSliceName]: globalSearchReducer,
});

const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: {
      actionsBlacklist: ["coreApi", "coreApi2"],
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        api.middleware,
        api2.middleware,
        relativePathApi.middleware,
        apiFrontOffice.middleware,
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const store = setupStore();

// небольшой костыль, чтобы асинхронно получить стор (например в loader'ах), чтобы убедиться, что он точно инициализирован
export const loadStore = () =>
  new Promise<typeof store>((resolve) => setTimeout(() => resolve(store), 0));
