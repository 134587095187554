import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { type ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { Outlet, type To } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, ICONS, LinkButton } from "ui-kit";

import { useReservationFormContext } from "../model/ReservationFormContext";
import {
  FormClose,
  FormFooter,
  FormHeader,
  FormStepper,
} from "./FormComponents";
import styles from "./ReservationFormLayout.module.scss";

export const ReservationFormLayout = () => {
  const { formatMessage } = useIntl();
  const [formValues, setFormValues] = useState({ bookingDate: dayjs() });
  const updateValues = (newValues: { bookingDate: Dayjs }) =>
    setFormValues((prev) => ({ ...prev, ...newValues }));
  return (
    <>
      <FormHeader
        title={formatMessage({ id: ETranslations.CREATION_RESERVATION })}
      >
        <FormClose to={"/dashboard"} />
      </FormHeader>
      <FormStepper
        stepsInfo={[
          {
            title: "Выбор даты",
            description: dayjs(formValues.bookingDate).format("DD MMMM, dddd"),
          },
          {
            title: "Количество гостей",
            description: "Необходимо изменить параметры",
            valid: false,
          },
          {
            title: "Гость",
            description:
              "К.В. Константинопольский +7\u00A0(917)\u00A0210-33-31",
            valid: true,
          },
          {
            title: "Стол",
            description: "18 стол, 3 зал",
            valid: true,
          },
          {
            title: "Прочее",
            description: "Дополнительная информация",
          },
        ]}
      />
      <Outlet context={{ formValues, updateValues }} />
      <FormFooter>footer</FormFooter>
    </>
  );
};
