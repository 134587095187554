import { restaurantsSelector } from "features/AppContex";
import {
  DialogFilterKind,
  DialogStatus,
  useDialogs,
} from "features/Dialogs/SDK";
import { useOrderCount } from "features/api/booking-order-api";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Badge } from "ui-kit";

const ARRAY_EMPTY: Array<any> = [];

export const UnansweredDialogCount = () => {
  const restaurants = useSelector(restaurantsSelector);
  const partners = useMemo(
    () => restaurants.map((r) => String(r.restaurant_id)),
    [restaurants],
  );

  const filters = useMemo(
    () => ({
      partners,
      customerTypes: ARRAY_EMPTY,
      projects: ARRAY_EMPTY,
      taskTypes: ARRAY_EMPTY,
      kind: DialogFilterKind.ALL,
      status: DialogStatus.ALL,
    }),
    [partners],
  );

  const { dialogs } = useDialogs(filters);

  const unansweredCount = useMemo(
    () =>
      dialogs?.reduce(
        (count, dialog) =>
          count +
          +(dialog.unanswered_count && dialog.status !== DialogStatus.CLOSED),
        0,
      ),
    [dialogs],
  );

  return Number.isFinite(unansweredCount) ? (
    <Badge value={unansweredCount! > 99 ? "99+" : unansweredCount!} />
  ) : null;
};

export const OrderRequestCount = () => {
  const { data } = useOrderCount();
  return Number.isFinite(data) ? (
    <Badge value={data! > 99 ? "99+" : data!} />
  ) : null;
};
