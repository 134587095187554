import { useFromProxyActions } from "features/BookingFormProxy";
import { SelectGuestFromList } from "features/ClientList/components/select-guest-from-list";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import React, { FC } from "react";
import type { Client } from "types/client";

import { isBookingView, isRegView } from "./HallSchemaV2/hall-helpersFn";
import { HallSchema } from "./HallSchemaV2/hall-schema";

interface Props {
  hallMode: HallMode;
}

export const RightSideComponent: FC<Props> = ({ hallMode }) => {
  const { setClient } = useFromProxyActions();
  const { switchMode } = useHallSchemaActions();
  const isGuestForm = [
    HallMode.REGISTRATION_GUESTS,
    HallMode.BOOKING_GUEST,
    HallMode.TABLE_BOOKINGS_EDIT_GUEST,
  ].includes(hallMode);

  const selectGuestHandler = (guest: Client) => {
    setClient({ client: guest });
    if (hallMode === HallMode.TABLE_BOOKINGS_EDIT_GUEST)
      switchMode(HallMode.REGISTRATION_TABLET);
    if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_TABLET);
    if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_TABLET);
  };

  if (isGuestForm) {
    return <SelectGuestFromList onSelectGuest={selectGuestHandler} />;
  }

  return <HallSchema />;
};
