import cn from "classnames";
import { TextareaHTMLAttributes, useLayoutEffect, useRef } from "react";
import { invariant } from "utils";

import { Labeled } from "../Labeled";
import styles from "./Textarea.module.scss";

// Функция для вычисления процента для управления цветом, начинаем изменять цвет с colorChangeThreshold % от maxLength
const getAdjustedColorPercentage = (
  textLength: number,
  maxLength: number,
  colorChangeThreshold: number,
) =>
  (
    Math.max(
      Math.min((textLength * 100) / maxLength, 100) - colorChangeThreshold,
      0,
    ) *
    (100 / (100 - colorChangeThreshold))
  ).toFixed(2);

type BaseTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaProps extends BaseTextareaProps {
  label?: string;
}

export function Textarea({ label, className, ...props }: TextareaProps) {
  return (
    <Labeled className={className} label={label} required={props.required}>
      <textarea {...props} className={styles.textarea} />
    </Labeled>
  );
}

const AutoResizeTextarea = ({
  defaultValue,
  maxLength,
  className,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement> & { defaultValue?: string }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    const textareaRefElement = textareaRef.current;
    textareaRefElement &&
      (textareaRefElement.style.height =
        textareaRefElement.scrollHeight + "px");
  }, []);

  return (
    <label
      className={styles.container}
      onChange={(e) => {
        const textareaElement = e.target;
        const dataElement = e.currentTarget.children[1];
        // Проверка на то, что textareaElement действительно HTMLTextAreaElement, а dataElement действительно HTMLDataElement
        invariant(
          textareaElement instanceof HTMLTextAreaElement &&
            dataElement instanceof HTMLDataElement,
          "Wrong AutoResizeTextarea structure",
        );
        // Устанавливаем высоту на основе содержимого
        const textareaHeight = textareaElement.offsetHeight;
        const textareaIdealHeight = textareaElement.scrollHeight;
        if (textareaHeight > textareaIdealHeight) {
          textareaElement.style.height = "auto";
        }
        textareaElement.style.height = textareaElement.scrollHeight + "px";
        // Устанавливаем значение каунтера равным длине текста в поле
        dataElement.value = String(textareaElement.value.length);
        // Если есть ограничение по максимальной длине, то управляем цветом каунтера (соотношение текущая длина текста / максимальная)
        if (maxLength) {
          dataElement.style.setProperty(
            "--text-length-percentage",
            `${getAdjustedColorPercentage(+dataElement.value, maxLength, 75)}%`,
          );
        }
      }}
    >
      <textarea
        className={cn(styles.textarea, styles.autoResize, className)}
        ref={textareaRef}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...props}
      />
      {maxLength && (
        <data
          style={{
            "--text-length-percentage": `${getAdjustedColorPercentage(defaultValue?.length || 0, maxLength, 75)}%`,
          }}
          value={defaultValue?.length || 0}
          className={styles.counter}
          data-max={maxLength}
        />
      )}
    </label>
  );
};

AutoResizeTextarea.displayName = "AutoResizeTextarea";

export { AutoResizeTextarea };
