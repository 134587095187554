import dayjs, { type Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { useRef, useState } from "react";
import { Button } from "ui-kit";
import { DetailsCollapse } from "ui-kit/Collapse/Collapse";
import { LeftArrow, RightArrow } from "ui-kit/ICONS/icons";



import styles from "./SmallCalendar.module.scss";


dayjs.extend(localeData);

const renderWeekdays = (
  weekdays: string[],
  weekStartsFrom: number,
  render: (weekday: string) => JSX.Element,
) => {
  const result = new Array<JSX.Element>();
  for (let i = weekdays.length - weekStartsFrom; i >= 1 - weekStartsFrom; i--) {
    result.push(render(weekdays.at(-i)!));
  }
  return result;
};

const getAdjustedMonthNumber = (startMonth: number, add: number) =>
  (startMonth + add) % 12;

export const SmallCalendar = ({
  name,
  defaultIsoDate,
  enabledDaysSet,
}: {
  name: string;
  defaultIsoDate: "YYYY-MM-DD" | (string & {});
  enabledDaysSet?: Set<string>;
}) => {
  const currentLocaleData = dayjs.localeData();
  const months = currentLocaleData.months();
  const weekStartsFrom = currentLocaleData.firstDayOfWeek();
  const weekdays = currentLocaleData.weekdaysMin();
  const [currentDate, setCurrentDate] = useState(() => dayjs(defaultIsoDate));
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const formattedDate = currentDate.format("YYYY-MM-");
  const now = dayjs().startOf("month");
  const todaysMonth = now.month();
  const todaysYear = now.year();
  const monthsDifferenceToToday = currentDate.diff(now, "month");

  return (
    <article className={styles.calendar}>
      <header className={styles.header}>
        <Button
          variant="phantom"
          onClick={() => setCurrentDate((prev) => prev.add(-1, "month"))}
        >
          <LeftArrow />
        </Button>
        <div>
          <DetailsCollapse
            title={currentDate.format("MMMM, YYYY")}
            className={styles.dateSelect}
            contentClassName={styles.dateOptions}
            icon={false}
            ref={detailsRef}
          >
            <ol
              style={{ "--start-year": todaysYear }}
              onChange={(e) => {
                e.stopPropagation();
                if (e.target instanceof HTMLInputElement) {
                  setCurrentDate(now.add(Number(e.target.value), "month"));
                  detailsRef.current && (detailsRef.current.open = false)
                }
              }}
            >
              {Array.from({ length: 24 }, (v, k) => (
                <li
                  key={k}
                  data-start-of-year={
                    !getAdjustedMonthNumber(todaysMonth, k) || undefined
                  }
                >
                  <input
                    id={`${k}`}
                    name="month-select"
                    value={k}
                    checked={k === monthsDifferenceToToday}
                    type="radio"
                    hidden
                  />
                  <label htmlFor={`${k}`} className={styles.dateOption}>
                    {months[getAdjustedMonthNumber(todaysMonth, k)]}
                  </label>
                </li>
              ))}
            </ol>
          </DetailsCollapse>
        </div>
        <Button
          variant="phantom"
          onClick={() => setCurrentDate((prev) => prev.add(1, "month"))}
        >
          <RightArrow />
        </Button>
      </header>
      <ol className={styles.weekdays}>
        {renderWeekdays(weekdays, weekStartsFrom, (weekday) => (
          <li key={weekday}>{weekday}</li>
        ))}
      </ol>
      <ol
        className={styles.days}
        style={{
          "--first-weekday":
            currentDate.startOf("M").day() + 1 - weekStartsFrom || 7,
        }}
      >
        {Array.from({ length: currentDate.daysInMonth() }, (_, dayIndex) => {
          const isoDate =
            `${formattedDate}` + `${dayIndex + 1}`.padStart(2, "0");
          return (
            <li key={isoDate}>
              <input
                id={isoDate}
                type="radio"
                hidden
                name={name}
                value={isoDate}
                disabled={enabledDaysSet && !enabledDaysSet.has(isoDate)}
                defaultChecked={isoDate === defaultIsoDate}
              />
              <label className={styles.label} htmlFor={isoDate} />
            </li>
          );
        })}
      </ol>
    </article>
  );
};