import cn from "classnames";
import { config } from "config";
import dayjs from "dayjs";
import { userSelector } from "features/AppContex";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Link } from "react-router-dom";
import { Button, ICONS, ModeSwitch } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { Exit } from "../ICONS/icons";
import { NavLinks } from "./NavLinks";
import styles from "./Sidebar.module.scss";

export const SidebarHeader = () =>
  useIsTablet() ? (
    <details open className={styles.sidebarControl}>
      <summary>
        <ICONS.Menu />
      </summary>
    </details>
  ) : (
    <Link to="/" className={styles.logo}>
      <img
        alt=""
        src={
          ICONS[`${config.BRAND}_LOGO` as keyof typeof ICONS] as
            | string
            | undefined
        }
      />
    </Link>
  );

const Clock = () => {
  const [now, setNow] = useState(dayjs.tz());
  const prevDate = useRef(now);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextNow = dayjs.tz();
      setNow(nextNow);
      prevDate.current = nextNow;
    }, 10e3);
    return () => clearInterval(interval);
  }, []);
  return (
    <time className={styles.dateTime} dateTime={now.format("HH:mm")}>
      {now.format("dd, D MMM")}
    </time>
  );
};

export const Sidebar = () => {
  const userApp = useSelector(userSelector);
  return (
    <>
      <SidebarHeader />
      <aside className={cn(styles.sidebar, styles.open)}>
        <NavLinks />
        <div className={styles.user}>
          <Form method="POST" action="logout">
            <Button
              className={styles.logout}
              type="submit"
              variant="phantom"
              value="kek"
            >
              <Exit />
            </Button>
          </Form>
          <span className={styles.name}>
            {userApp.username || userApp.user_name || ""}
          </span>
          <Clock />
          <ModeSwitch />
        </div>
      </aside>
    </>
  );
};
